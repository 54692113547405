"use client"

import { useEffect } from "react"
import { captureException } from "@sentry/nextjs"

import RoundedCta from "~/components/ui/RoundedCta"
import { logger } from "~/utils/logger"

import * as css from "./styles.css"

export type ErrorHandlerProps = { title?: string; error: Error; reset: () => void }

function ErrorHandler({ title = "Something went wrong!", reset, error }: ErrorHandlerProps) {
  useEffect(() => {
    logger.error("[ErrorHandler]: ", error)
    captureException(error)
  }, [error])

  return (
    <div className={css.ErrorHandler} data-comp="UI/ErrorHandler">
      <div className={css.content}>
        <h2 className={css.title}>{title}</h2>
        <RoundedCta
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
        >
          Try again
        </RoundedCta>
      </div>
    </div>
  )
}

export default ErrorHandler
